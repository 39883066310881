body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
}
header {
  background: url("./assets/test-bg-2.png") no-repeat center center;
  background-size: cover;
  text-align: center;
  max-height: 500px;
  align-content: center;
  height: 500px;
  opacity: 0.9;
}
.logo-bg {
  width: 100%;
}
section {
  margin: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.card {
  background: #f0f0f0;
  border: 1px solid #ddd;
  padding: 20px;
  margin: 10px;
  flex-basis: calc(33% - 20px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 20px;
}
button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
@media (max-width: 600px) {
  .card {
    flex-basis: 100%;
  }
}
